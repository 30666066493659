import { StoreProps } from '@interfaces/StoreState'
import { createSelector } from '@reduxjs/toolkit'
import { ELECTIVE, REG_STATUS } from '@utils/constants'

const MULTI_ELECTIVE = {
  language: 'English',
  level: ['9', '10'],
}

const selectRegistrationWrappers = (state: StoreProps) =>
  state?.userProfile?.info?.StudentProfile?.StudentProfileInfo?.RegistrationWrappers ?? []
const selectActiveMaterial = (state: StoreProps) => state?.dashboard?.activeMaterial ?? undefined

export const selectRegistration = createSelector(
  [selectRegistrationWrappers, selectActiveMaterial],
  (registrationWrappers, activeMaterial) => {
    let registration = registrationWrappers?.find(
      ({ RegistrationInfo }) => RegistrationInfo?.Materials?.[0]?.LPID === activeMaterial
    )

    /**
     * If registration var is empty,
     * then we might try to match it with RegistrationId
     */
    if (!registration) {
      registration = registrationWrappers?.find(
        ({ RegistrationInfo }) => RegistrationInfo?.RegistrationId === activeMaterial
      )
    }
    return registration?.RegistrationInfo
  }
)

export const selectIsActive = createSelector([selectRegistration], (registration) => {
  return registration?.Status === REG_STATUS.ACTIVE
})

export const selectCanAddMoreElective = createSelector([selectRegistration], (registration) => {
  const electives = registration?.Materials?.filter(({ IsElective, IsTest }) => IsElective && !IsTest)

  return (electives || []).length < 3
})

export const selectElectiveRequired = createSelector([selectRegistration], (registration) => {
  return registration?.ElectiveRequired === ELECTIVE.REQUIRED
})

export const selectCoreMaterial = createSelector([selectRegistration], (registration) => {
  return registration?.Materials?.find(({ IsElective, IsTest }) => !IsElective && !IsTest)
})

export const selectElectiveMaterials = createSelector([selectRegistration], (registration) => {
  return registration?.Materials?.filter(({ IsElective, IsTest }) => IsElective && !IsTest)
})

export const selectIsElectiveMulti = createSelector([selectRegistration], (registration) => {
  return (
    registration?.Language === MULTI_ELECTIVE.language &&
    MULTI_ELECTIVE.level.includes(`${registration?.LanguageLevel}`)
  )
})

export default { selectRegistration, selectIsActive }
