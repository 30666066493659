import styled, { css } from 'styled-components'
import { H3, P } from '@berlitz/globals'

export const Wrapper = styled.div`
  text-align: center;
  height: max-content;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  /* Size not available in themes */
  width: 106px;
  height: 106px;
  background-color: ${({ theme, backgroundColor }) => theme.palette[backgroundColor]};
  margin: 0 auto ${({ theme }) => theme.space.xxl};
  border-radius: 50%;
`

export const TitleWrapper = styled.div`
  color: ${({ theme }) => theme.palette.text00};
  font-size: ${({ theme }) => theme.fontSize.xl};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-style: normal;
  font-stretch: normal;
  line-height: 1.19;
  letter-spacing: 1.1px;
  margin-bottom: ${({ theme }) => theme.space.sm};
`
export const SpinnerWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.lg};
`

export const InlineSpinner = styled.div`
  position: absolute;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.lg};
`

export const IconCircle = styled.div`
  width: 106px;
  height: 106px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.gray100};
  border-radius: ${({ theme }) => theme.layout.xxxl};
  margin: auto;
  margin-bottom: ${({ theme }) => theme.space.lg};

  ${({ theme, color }) =>
    color &&
    color === 'success' &&
    css`
      background-color: ${({ theme }) => theme.palette.success100};
    `}

  ${({ theme, color }) =>
    color &&
    color === 'warning' &&
    css`
      background-color: ${({ theme }) => theme.palette.warning100};
    `}

  ${({ theme, color }) =>
    color &&
    color === 'error' &&
    css`
      background-color: ${({ theme }) => theme.palette.danger100};
    `}

  svg {
    font-size: 64px;
    color: ${({ theme }) => theme.colors.brandPrimaryContrast};
  }
`

export const CenterText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const ExtraSmallText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.xxxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  margin: ${({ theme }) => theme.space.xs};
`
export const ActionButtons = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => theme.space.lg};

  button {
    border: none;
    margin-bottom: ${({ theme }) => theme.space.md};

    &:not(:first-child) {
      margin-left: ${({ theme }) => theme.space.xs};
    }
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: ${({ theme }) => theme.colors.brandPrimary};
  }
`

export const TransparentContainer = styled.div`
  div[data-key='max-modal'] {
    background-color: rgba(45, 47, 61, 0.85);
  }

  div[data-key='max-modal__box'] {
    background-color: initial;
    box-shadow: initial;
    height: 100%;
    width: 100%;

    div {
      background-color: initial;

      button[data-key='max-modal__box__button--close'] {
        outline: none;
        span {
          color: ${({ theme }) => theme.colors.brandPrimaryContrast};
        }
      }

      @media (max-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding: 0;
      }
    }
  }
`

export const Box = styled.div`
  ${H3} {
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }

  textarea {
    resize: none;
    padding-right: ${({ theme }) => theme.space.lg};
  }

  button {
    padding-top: ${({ theme }) => theme.space.xs};
    padding-bottom: ${({ theme }) => theme.space.xs};
    margin-right: ${({ theme }) => theme.space.xs};
  }
`
export const Center = styled.div`
  text-align: center;
`

export const Overrides = styled.div<{ hideCloseButton?: boolean }>`
  button[aria-label='Close Modal'] {
    cursor: ${({ hideCloseButton }) => (hideCloseButton ? 'default' : 'pointer')};
    span {
      color: ${({ theme, hideCloseButton }) =>
        hideCloseButton ? theme.colors.brandPrimaryContrast : theme.colors.brandPrimary};
    }
  }
`

export const StyledP = styled(P)`
  font-size: ${({ theme }) => theme.fontSize.xxs};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  color: ${({ theme }) => theme.colors.brandSupporting04};
`
