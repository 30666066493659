import PortalProgram from '@classes/Program'
import { Materials, ProgramStudent, ProgramStudentInfo } from '@interfaces/Student'
import { DATE_FORMAT, INVALID_SCHEDULING_PROGRAM_TYPES, PROGRAM_TYPES } from '@utils/constants'
import { REG_STATUS } from '@utils/constants'
import { allProgramsBlocks } from '@utils/helpers/programs'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import uniq from 'lodash/uniq'
import moment from 'moment'

class PortalPrograms {
  programs: ProgramStudent[]

  constructor(programs: ProgramStudent[]) {
    this.programs = programs
  }

  getCurrentProgram = (programId: string) =>
    (
      this.programs?.find(
        (currentProgram) =>
          get(currentProgram, 'ProgramStudentInfo.ProgramId') === programId ||
          currentProgram.ProgramStudentInfo.Materials?.some((material) => material?.LPID === programId)
      ) || {}
    ).ProgramStudentInfo

  getProgram = (programId: string) => new PortalProgram(this.getCurrentProgram(programId))

  getProgramMaterials = (programId: string): Materials[] => this.getCurrentProgram(programId)?.Materials || []

  programsNotEnoughLessonLeftPerSession = (programs = this.programs) =>
    programs.filter(({ ProgramStudentInfo }) => !new PortalProgram(ProgramStudentInfo).enoughLessonLeftPerSession())
      .length === programs.length

  isProgramLessonsAllBooked = (programs = this.programs) =>
    programs.filter(({ ProgramStudentInfo }) => this.getProgram(ProgramStudentInfo.ProgramId).areLessonsAllBooked())
      .length === this.programs.length

  allProgramsBlocks = () => allProgramsBlocks(this.programs)

  allProgramsNoLessonsRemaining = (programs = this.programs) =>
    programs.filter(({ ProgramStudentInfo }) => !this.getProgram(ProgramStudentInfo.ProgramId).getLessonsLeft())
      .length === this.programs.length

  getNonFlexPrograms = () =>
    this.programs?.filter(({ ProgramStudentInfo }) => !this.getProgram(ProgramStudentInfo?.ProgramId).isFlexProgram())

  areNonFlexAllBooked = () => this.isProgramLessonsAllBooked(this.getNonFlexPrograms())

  hasFlexPrograms = (): boolean => !!this.FlexPrograms?.length

  hasFaceToFacePrograms = (): boolean =>
    this.programs?.some(({ ProgramStudentInfo }) => this.getProgram(ProgramStudentInfo?.ProgramId).isProgramF2F())

  hasLiveOnlinePrograms = (): boolean =>
    this.programs?.some(({ ProgramStudentInfo }) =>
      this.getProgram(ProgramStudentInfo?.ProgramId).isProgramLiveOnline()
    )

  studentHasLiveOnlineInGroup = (): boolean =>
    this.programs?.some(({ ProgramStudentInfo }) =>
      this.getProgram(ProgramStudentInfo?.ProgramId).isProgramLiveOnlineInGroup()
    )

  onlineProgramsOnly = () =>
    this.programs?.filter(({ ProgramStudentInfo }) => new PortalProgram(ProgramStudentInfo).isProgramOnline())

  hasOnlineProgram = () => this.onlineProgramsOnly()?.length

  isProgramFlex = (programId?: string | null) =>
    (this.FlexPrograms.length && !programId) || (programId && this.getProgram(programId).isFlexProgram())

  isProgramFlexV2 = (materialId: string | null | undefined) =>
    this.isProgramFlex(this.MaterialProgram[materialId || ''])

  getSortedPrograms = (getDateWithUserTimezone?: any) =>
    sortBy(
      this.programs,
      (program) => !this.getProgram(program.ProgramStudentInfo.ProgramId).isProgramValidForScheduling(),
      (program) =>
        moment(program.ProgramStudentInfo.ContractEndDate, DATE_FORMAT.BASIC).diff(getDateWithUserTimezone(), 'days') <
        0
    )

  studentProgramsSortedDropdown = (
    getDateWithUserTimezone?: any,
    nonFlex: boolean = false,
    withBoDSort: boolean = false
  ) => {
    const sorted = this.getSortedPrograms(getDateWithUserTimezone)
      .filter(({ ProgramStudentInfo }) => !ProgramStudentInfo.PortalNotNecessary)
      .filter(({ ProgramStudentInfo }) => {
        // filter for https://berlitz.atlassian.net/browse/SCH-690
        let defaultFilter =
          ProgramStudentInfo.Status !== REG_STATUS.CANCELLED &&
          ProgramStudentInfo.Status !== REG_STATUS.COMPLETED &&
          ProgramStudentInfo.Status !== REG_STATUS.DRAFT &&
          !(
            getDateWithUserTimezone &&
            ProgramStudentInfo.Status === REG_STATUS.ACTIVE &&
            moment(ProgramStudentInfo.ContractEndDate, DATE_FORMAT.BASIC).diff(getDateWithUserTimezone(), 'days') < 0
          )

        // https://berlitz.atlassian.net/browse/SCH-817
        if (nonFlex) {
          return !new PortalProgram(ProgramStudentInfo).isFlexProgram() && defaultFilter
        }

        return defaultFilter
      })
      .filter(
        // filter for https://berlitz.atlassian.net/browse/SCH-740
        ({ ProgramStudentInfo }) =>
          !(
            [REG_STATUS.ACTIVE, REG_STATUS.PENDING_START].includes(ProgramStudentInfo.Status || '') &&
            ProgramStudentInfo.PortalNotNecessary
          )
      )
      .filter(
        // filter for https://berlitz.atlassian.net/browse/PS-169
        ({ ProgramStudentInfo }) => getDateWithUserTimezone()?.isBefore(ProgramStudentInfo.ContractEndDate, 'day')
      )

    let sortedProgramDropdown: {
      label: string
      value: string
      data: ProgramStudentInfo
    }[] = sorted.map((program) => {
      const { Language, DeliveryMode, ProgramName, ProgramEnrollDate, Status } = program.ProgramStudentInfo
      const DeliveryModeText = DeliveryMode ? `${DeliveryMode} - ` : ''

      const expired =
        getDateWithUserTimezone &&
        program.ProgramStudentInfo.Status === REG_STATUS.ACTIVE &&
        moment(program.ProgramStudentInfo.ContractEndDate, DATE_FORMAT.BASIC).diff(getDateWithUserTimezone(), 'days') <
          0
          ? ' (Expired)'
          : ''

      return {
        label: `${Language} - ${DeliveryModeText}${ProgramName} - ${moment(ProgramEnrollDate).format(
          'MMMM DD, YYYY'
        )} - ${Status}${expired}`,
        value: program?.ProgramStudentInfo?.ProgramId,
        data: program?.ProgramStudentInfo,
      }
    })

    if (withBoDSort) {
      sortedProgramDropdown = sortBy(
        sortedProgramDropdown.map((dropdown) => ({
          programId: dropdown.value,
          isBoD: this.getProgram(dropdown.data.ProgramId).isBerlitzOnDemand(),
        })),
        'isBoD'
      ).map(({ programId }) => sortedProgramDropdown.find((dropdown) => dropdown.value === programId)!)
    }

    return sortedProgramDropdown
  }

  get Programs() {
    return this.programs?.map(({ ProgramStudentInfo }) => this.getProgram(ProgramStudentInfo?.ProgramId))
  }

  get FlexPrograms() {
    return this.Programs?.filter(({ isFlexProgram }) => isFlexProgram())
  }

  get AreAllProgramsFlex() {
    return this.Programs.length === this.FlexPrograms?.length
  }

  get MaterialProgram() {
    return this.programs.reduce((p, program) => {
      program.ProgramStudentInfo.Materials?.forEach(({ LPID }) => {
        const key = LPID || ''
        p[key] = program.ProgramStudentInfo.ProgramId
      })
      return p
    }, {})
  }

  uniqString = (data?: (string | undefined)[]) => uniq(data).filter(Boolean).join()

  get ProgramLanguages() {
    return this.uniqString(this.Programs?.map((program) => program.program?.Language))
  }

  get ProductTypes() {
    return this.uniqString(this.Programs?.map((program) => program.program?.ProgramType))
  }

  get EventsPrograms() {
    return this.Programs.filter(
      (program) =>
        program?.program?.Status !== REG_STATUS.CANCELLED &&
        program?.program?.Status !== REG_STATUS.COMPLETED &&
        program?.program?.Status !== REG_STATUS.DRAFT
    )
  }

  get IsMultiplePrograms() {
    const programTypes = this.Programs.map((program) => program?.ProgramTypeDisplay)
    return uniq(programTypes).length > 1
  }

  get ContainsGroupConversation() {
    return this.Programs.some((program) => this.getProgram(program.program?.ProgramId ?? '').IsGroupConversation)
  }

  containsProgramType = (
    programType:
      | 'CT'
      | 'LIVE_ONLINE'
      | 'F2F'
      | 'HYBRID'
      | 'FLEX'
      | 'JPN_FLEX'
      | 'ON_DEMAND'
      | 'FLEX_BOD'
      | 'ON_DEMAND_1'
  ) => {
    return this.Programs.some((program) => PROGRAM_TYPES[programType].includes(program.program?.ProgramType ?? ''))
  }

  hasMoreInactiveAndNoActivePrograms = (getDateWithUserTimezone?: any) => {
    const programs = this.getSortedPrograms(getDateWithUserTimezone)
    const inactivePrograms = programs.filter(
      ({ ProgramStudentInfo }) => !new PortalProgram(ProgramStudentInfo).IsActive
    )
    const activePrograms = programs.filter(({ ProgramStudentInfo }) => new PortalProgram(ProgramStudentInfo).IsActive)
    return inactivePrograms.length > 1 && !activePrograms.length
  }

  // this is for the active course component to determine program count
  get ValidSchedulingPrograms() {
    return this.programs.filter(
      (program) => !INVALID_SCHEDULING_PROGRAM_TYPES.has(program.ProgramStudentInfo.Status || '')
    )
  }

  get HasBoDProgram() {
    return this.Programs?.some((program) => program.isBerlitzOnDemand())
  }
}

export default PortalPrograms
