import { useMutation } from '@apollo/react-hooks'
import StudentProfile from '@classes/StudentProfile'
import Error from '@components/ErrorPublic'
import Header from '@components/Header'
import PageOverlay from '@components/PageOverlay'
import PreloadedRequests from '@components/PreloadedRequests'
import SidebarMenu from '@components/SidebarMenu'
import useCustomLazyQuery from '@hooks/useCustomLazyQuery'
import useTimeFormat from '@hooks/useTimeFormat'
import { StoreProps } from '@interfaces/StoreState'
import { StudentProfileInfo } from '@interfaces/Student'
import { GetUserProfile, UserProfileProps } from '@interfaces/UserProfile'
import { Container, Wrapper } from '@layouts/PageLayout/style'
import Permissions from '@layouts/Permissions'
import UserTranslations from '@layouts/PortalTranslations/UserTranslations'
import { PUT_STUDENTS } from '@mutations/index'
import { GET_USER_PROFILE } from '@queries/userProfile'
import { resetPreloadedData } from '@redux/actions/preloadedData'
import { fetchProfileSuccess } from '@redux/actions/userProfile'
import { PROFILE_TYPE } from '@utils/constants'
import { isPagePublic, NON_DASHBOARD_ROUTES } from '@utils/page-config'
import { startsWith } from 'lodash'
import isEqual from 'lodash/isEqual'
import pick from 'lodash/pick'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import userflow from 'userflow.js'
import { initHJ } from 'utils/hotjar'
import { resetLastActive } from '@redux/actions/userProfile'
import { isMobile } from 'react-device-detect'
import { updateAuthorized } from '@redux/actions/session'

const ProfileComponent: React.FC<{ data?: GetUserProfile; error?: Error; loading?: boolean }> = ({
  data,
  error,
  children,
  loading,
}) => {
  const dispatch = useDispatch()
  const { getAccountFormFields } = useTimeFormat()
  const [fetchedProfile, setFetchedProfile] = useState<UserProfileProps | undefined>()
  const [isFetchingProfile, setIsFetchingProfile] = useState(true)
  const { isFetching, resetActive, lastDateActive, session } = useSelector(({ userProfile, session }: StoreProps) => ({
    isFetching: userProfile?.isFetching,
    resetActive: userProfile?.resetLastActive,
    lastDateActive: userProfile?.lastDateActive,
    session: session,
  }))

  const [updateLoginTime] = useMutation<StudentProfileInfo>(PUT_STUDENTS, {
    onCompleted: () => {
      localStorage.removeItem(`${data?.getUserProfile?.SFId}-loginTime`)
    },
  })

  const initHJUser = () => {
    if (data?.getUserProfile) {
      const studentProfile = new StudentProfile(data.getUserProfile?.StudentProfile?.StudentProfileInfo)
      const device_type = isMobile ? 'mobile' : 'desktop'
      initHJ()
      // @ts-ignore
      window.hj('identify', data.getUserProfile.SFId, {
        displayLanguage: studentProfile?.student?.PortalLanguage,
        languageLearning: studentProfile?.LanguageLearning,
        productType: studentProfile?.ProductTypes,
      })

      if (process.env.USERFLOW_TOKEN && studentProfile?.student) {
        userflow.init(process.env.USERFLOW_TOKEN)
        userflow.identify(studentProfile?.SFId, {
          signed_up_at: studentProfile?.student?.firstLogin || new Date().toISOString(),
          language_display: studentProfile?.student?.PortalLanguage,
          locale_code: studentProfile?.student?.PortalLanguage,
          user_type: data.getUserProfile?.Type,
          account_name: studentProfile?.student?.Company,
          device_type,
          email: studentProfile?.student?.Email,
        })
      }

      if (process.env.USERFLOW_TOKEN && data.getUserProfile?.Type === PROFILE_TYPE.INSTRUCTOR) {
        const instructorProfile = data.getUserProfile
        userflow.init(process.env.USERFLOW_TOKEN)
        userflow.identify(instructorProfile?.SFId, {
          language_display: instructorProfile?.InstructorProfile?.instructorInfo?.PortalLanguage,
          locale_code: instructorProfile?.InstructorProfile?.instructorInfo?.PortalLanguage,
          user_type: instructorProfile?.Type,
          device_type,
        })
      }

      // @ts-ignore
      if (window && window.dataLayer) {
        if (data.getUserProfile?.Type === PROFILE_TYPE.STUDENT) {
          // @ts-ignore
          window.dataLayer.push({
            event: 'ddsetUser',
            userId: studentProfile?.SFId,
            userType: data.getUserProfile?.Type,
            displayLanguage: studentProfile?.student?.PortalLanguage,
            languageLearning: studentProfile?.LanguageLearning,
            productType: studentProfile?.ProductTypes,
          })
        } else if (data.getUserProfile?.Type === PROFILE_TYPE.INSTRUCTOR) {
          const instructorProfile = data.getUserProfile
          // @ts-ignore
          window.dataLayer.push({
            event: 'ddsetUser',
            userId: instructorProfile?.SFId,
            userType: data.getUserProfile?.Type,
          })
        }
      }
    }
  }
  useEffect(() => {
    setFetchedProfile(data?.getUserProfile)
    setIsFetchingProfile(isFetching)
    initHJUser()
  }, [])

  useEffect(() => {
    if (data?.getUserProfile && !isEqual(fetchedProfile, data?.getUserProfile)) {
      dispatch(fetchProfileSuccess(data.getUserProfile))
      setFetchedProfile(data?.getUserProfile)
      setIsFetchingProfile(false)
      initHJUser()
    }
  }, [data, loading])

  useEffect(() => {
    const lastLoginTime = localStorage.getItem(`${data?.getUserProfile?.SFId}-loginTime`) ?? lastDateActive
    if (data?.getUserProfile?.StudentProfile && resetActive && Boolean(lastLoginTime) && !session.isAdmin) {
      const loginTime = lastLoginTime
      const values = data?.getUserProfile?.StudentProfile?.StudentProfileInfo
      const studentInput = [
        {
          StudentProfileInfo: {
            ...pick(values, getAccountFormFields()),
            loginTime,
          },
        },
      ]

      updateLoginTime({
        variables: {
          studentInput,
        },
      })

      dispatch(resetLastActive(false))
    }
  }, [resetActive, data])

  if (error) {
    return <Error statusCode={403} />
  }

  if (isFetchingProfile) {
    return <PageOverlay />
  }

  return <>{children}</>
}

const PortalLayout: React.FC = ({ children }) => {
  const dispatch = useDispatch()
  const router = useRouter()
  const isDashboardRoute = !NON_DASHBOARD_ROUTES.includes(router.pathname)
  const isPublicPage = isPagePublic(router.pathname)
  const [preExecutionDone, setPreExecutionDone] = useState(false)
  const { hasGroups, authorized, roles, isFetching, isAdmin, authorizedKey } = useSelector(
    ({ session, userProfile }: StoreProps) => ({
      hasGroups: session?.cognitoGroups?.length,
      authorized: session?.authorized,
      authorizedKey: session?.authorizedKey,
      roles: session.cognitoGroups,
      isFetching: userProfile?.isSilentFetching,
      isAdmin: session.isAdmin,
    })
  )

  const role = roles?.find((roleData) => roleData === __USER_GROUP_INSTRUCTORS__)
    ? PROFILE_TYPE.INSTRUCTOR
    : PROFILE_TYPE.STUDENT
  const [getUserProfile, { data, error, loading }] = useCustomLazyQuery<GetUserProfile>(GET_USER_PROFILE)
  const [isFirstLoadDone, setIsFirstLoadDone] = useState(false)
  const [isActivityPage, setIsActivityPage] = useState(false)
  // this is for when the user changes language display
  useEffect(() => {
    if (!isFirstLoadDone || !isFetching) {
      return
    }
    getUserProfile({
      Type: role,
      Id: authorized,
      Key: authorizedKey,
    })
  }, [isFetching])

  useEffect(() => {
    getUserProfile({
      Type: role,
      Id: authorized,
      Key: authorizedKey,
    })
    setIsFirstLoadDone(true)
    // remove temp flags in local storage
    Object.keys(localStorage).forEach((i) => {
      if (startsWith(i, 'temp')) {
        localStorage.removeItem(i)
      }
    })
  }, [])

  useEffect(() => {
    // this is to refetch preloaded to avoid caching data
    if (router?.pathname !== '/') {
      dispatch(resetPreloadedData())
    }

    setIsActivityPage(router?.pathname?.includes('/activity'))
  }, [router])

  useEffect(() => {
    // always force recordID to authorized value on student
    if (!loading && role === PROFILE_TYPE.STUDENT) {
      dispatch(
        updateAuthorized(
          data?.getUserProfile?.StudentProfile?.StudentProfileInfo?.RecordId || authorized || '',
          'StudentId'
        )
      )
    }
    setPreExecutionDone(true)
  }, [loading, data])
  return (
    <>
      {role === PROFILE_TYPE.STUDENT && authorized && router.pathname === '/' && !preExecutionDone && (
        <PreloadedRequests />
      )}
      <UserTranslations defaultLang={localStorage.getItem('appLocale') || 'en'}>
        {isDashboardRoute && <Header />}
        <Wrapper withHeader={isDashboardRoute} isActivityPage={isActivityPage} isAdmin={isAdmin}>
          {isDashboardRoute ? <SidebarMenu /> : null}
          <Permissions>
            <Container
              withHeader={isDashboardRoute}
              hasSidebar={Boolean(!isPublicPage || (isPublicPage && (hasGroups || authorized)))}
              isActivityPage={isActivityPage}
            >
              {authorized ? (
                <ProfileComponent data={data} error={error} loading={loading}>
                  {children}
                </ProfileComponent>
              ) : (
                children
              )}
            </Container>
          </Permissions>
        </Wrapper>
      </UserTranslations>
    </>
  )
}

export default PortalLayout
