import { FLEX_HERO_CONTENT_ID, ON_DEMAND_HERO_CONTENT_ID, CERTIFICATE_ACHIEVEMENT_ID } from '@utils/constants'
import gql from 'graphql-tag'

const handleLocale = (langCode: string) => {
  // special case for chinese simplified as the lang code is different for cs and sf
  // zh-CN - sf; zh-chs - contentstack
  return langCode?.toLowerCase() === 'zh-cn' ? 'zh-chs' : langCode?.toLowerCase()
}

export const GET_FLEX_CONTENT = (langCode: string) => {
  const locale = handleLocale(langCode)
  if (process.env.CHINA_DEPLOYMENT) {
    return gql`
      query {
        contentstack: localizationFlexHero(locale: "${locale}", uid: "${FLEX_HERO_CONTENT_ID}", isOnDemand: ${false}) {
          data
        }
      }
    `
  }

  return gql`
    query {
      contentstack(
        query: "query{ flex_hero( uid: \\"${FLEX_HERO_CONTENT_ID}\\", locale: \\"${locale}\\" ){ title orientation_video_cta { href title } body returning_students_body returning_students_title maintenance_title maintenance_description maintenance_body maintenance_orientation_video_cta { href title } } }"
      ) {
        data
      }
    }
  `
}

export const GET_ON_DEMAND_FLEX_CONTENT = (langCode: string) => {
  const locale = handleLocale(langCode)
  if (process.env.CHINA_DEPLOYMENT) {
    return gql`
      query {
        contentstack: localizationFlexHero(locale: "${locale}", uid: "${ON_DEMAND_HERO_CONTENT_ID}", isOnDemand: ${true}) {
          data
        }
      }
    `
  }

  return gql`
    query {
      contentstack(
        query: "query{ on_demand_flex_hero( uid: \\"${ON_DEMAND_HERO_CONTENT_ID}\\", locale: \\"${locale}\\" ){ title orientation_video_cta { href title } body returning_students_body returning_students_title maintenance_title maintenance_description maintenance_body maintenance_orientation_video_cta { href title } group_conversation_class_orientation_cta { href title } } }"
      ) {
        data
      }
    }
  `
}

export const GET_CERTIFICATE_ACHIEVEMENT = (langCode: string) => {
  const locale = handleLocale(langCode)

  return gql`
      query {
        contentstack: localizationCertificateOfAchievement(locale: "${locale}", uid: "${CERTIFICATE_ACHIEVEMENT_ID}") {
          items {
            locale
            title
            url
          }
        }
      }
    `
}
