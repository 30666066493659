import { Auth } from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import Error from '@components/ErrorPublic'
import PageOverlay from '@components/PageOverlay'
import { userLoggedIn, userNotAuthorised } from '@redux/actions/session'
import { SSO_URL } from '@utils/api-url'
import { configureAmplify, signOutUser } from '@utils/aws-api'
import { configureAWSCognito } from '@utils/aws-cognitoIdentity'
import { PORTALS_VAR } from '@utils/constants'
import { isPagePublic } from '@utils/page-config'
import { UserType } from 'aws-sdk/clients/cognitoidentityserviceprovider'
import isEmpty from 'lodash/isEmpty'
import Router, { useRouter } from 'next/router'
import * as queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import customQuery from '@utils/helpers/custom-query'
import { get } from 'lodash'
import { GET_DECRYPTS_WITH_TYPE } from '@queries/decrypt'
import { resetLastActive } from '@redux/actions/userProfile'
import { getSession } from '@utils/aws-usersession'
import { CognitoUserProps } from '@interfaces/StoreState'

export interface LoginAsState {
  aas?: string | null | string[]
  aai?: string | null | string[]
}

const AWSAuth: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { federated_identity, ...additionalParams } = queryString.parse(window.location.search)
  const [fetching, setFetching] = useState(true)
  const [user, setUser] = useState<(UserType & { username: string; attributes: any }) | null>(null)
  const [didFirstCall, setDidFirstCall] = useState(false)
  const [isSigningIn, setIsSigningIn] = useState(false)
  const dispatch = useDispatch()
  const router = useRouter()

  const { access_token, id_token, state } = queryString.parse(window.location.hash)
  const { code, aas, aai } = queryString.parse(window.location.search)
  const { suid, iuid } = queryString.parse(window.location.search)
  let stateQueryParams = state || null

  if (stateQueryParams) {
    try {
      stateQueryParams = atob(state as string)
    } catch (e) {
      // do nothing; it just meeans the state is not encoded and we can use it as is
    }
  }

  const callDecryptApi = async (type: string, encryptedId: string, token: string) => {
    let cleanId = encryptedId.split('%20').join('+').split(' ').join('+')
    return customQuery(
      GET_DECRYPTS_WITH_TYPE,
      {
        variables: {
          encrypts: decodeURIComponent(cleanId),
          type,
        },
      },
      token
    )
  }

  const getLoggedInAs = () => {
    let sfID: LoginAsState = { aas, aai }
    if (!sfID.aas || !sfID.aai) {
      sfID = queryString.parse(localStorage.getItem(PORTALS_VAR.LOCAL_SFID) as string)
    }
    return sfID
  }
  const [loginAs] = useState<LoginAsState>(getLoggedInAs())

  // tslint:disable-next-line:variable-name
  const getAwsUserData = async (refresh_token?: string) => {
    try {
      await getSession('AwsAuth')
      const authUser = await Auth.currentAuthenticatedUser()
      const cognitoUser: CognitoUserProps = {
        username: authUser.username,
        attributes: authUser.attributes,
        signInUserSession: {
          idToken: {
            jwtToken: authUser?.signInUserSession?.idToken?.jwtToken,
            payload: authUser?.signInUserSession?.idToken?.payload,
          },
        },
      }
      if (suid || iuid) {
        try {
          let token = authUser.getSignInUserSession().getIdToken()
          let encryptedId = (suid as string) || (iuid as string)
          let impostor = { aas: undefined, aai: undefined } as LoginAsState
          let type = suid ? 'student' : 'instructor'
          const response = await callDecryptApi(type, encryptedId, token.jwtToken)
          const result = get(response, 'data.decrypt.decrypts[0]', '')
          if (suid) {
            impostor.aas = result
          } else {
            impostor.aai = result
          }
          localStorage.setItem(PORTALS_VAR.LOCAL_SFID, queryString.stringify(impostor))
          dispatch(userLoggedIn(cognitoUser, { ...impostor }))
        } catch (error) {
          dispatch(userLoggedIn(cognitoUser, { ...loginAs }))
        }
      } else {
        dispatch(userLoggedIn(cognitoUser, { ...loginAs }))
      }
      setUser(authUser)
      setFetching(false)
      refresh_token &&
        authUser &&
        typeof refresh_token === 'string' &&
        localStorage.setItem(
          `${PORTALS_VAR.COGNITO_IDENTIFIER}.${process.env.AWS_USER_POOLS_WEB_CLIENT_ID}.${authUser?.username}.${PORTALS_VAR.REFRESH_TOKEN}`,
          refresh_token
        )

      let qs = '/'
      if (stateQueryParams) {
        qs += `?${stateQueryParams}`
      }
      if (suid) {
        Router.push(qs)
      }

      if (iuid) {
        Router.push('/instructors')
      }
    } catch (e) {
      /**
       * If you start noticing constant redirections when developing in local
       * consider commenting out the following line
       * so that you can see the error message
       */
      // console.log(e)
      // const authUser = await Auth.currentAuthenticatedUser()
      // setUser(authUser)
      dispatch(userNotAuthorised(e))
      setFetching(false)
      setUser(null)
    }
  }

  const checkUser = async () => {
    // avoid reduntant getting of data when signin params are available
    if (!access_token && !id_token && !code) {
      await getAwsUserData()
    }
  }

  const setLoginTime = () => {
    const userGMTTime = moment.utc(moment.tz(user?.attributes?.zoneinfo || moment.tz.guess())).format()
    localStorage.setItem(`${user?.attributes?.['custom:customerId']}-loginTime`, userGMTTime)
  }

  useEffect(() => {
    const { refresh_token } = queryString.parse(window.location.hash)
    Hub.listen('auth', ({ payload: { event } }) => {
      switch (event) {
        case 'signIn':
          __DEV__ && console.log('user sign in')
          // @ts-ignore
          getAwsUserData(refresh_token)
          setIsSigningIn(true)
          break
        case 'signOut':
          __DEV__ && console.log('sign out')
          setUser(null)
          break
        case 'signIn_failure':
          signOutUser()
          setFetching(false)
          setUser(null)
          __DEV__ && console.log('user sign in failed')
          break
        case 'configured':
          __DEV__ && console.log('auth module configured')
          checkUser()
          break
        case 'parsingCallbackUrl':
          __DEV__ && console.log('parsingCallbackUrl')
          break
        case 'tokenRefresh':
          dispatch(resetLastActive(true))
          break
        default:
          break
      }
    })
    configureAWSCognito()
    configureAmplify(stateQueryParams)
    localStorage.removeItem('loadedLocale')
  }, [])

  const isPublicPage = isPagePublic(router.pathname)
  if (!fetching && isEmpty(user) && !isPublicPage) {
    if (federated_identity) {
      let qs = federated_identity
      if (!isEmpty(additionalParams)) {
        qs = `${qs}&state=${btoa(queryString.stringify(additionalParams))}`
      }
      window.location.href = `${SSO_URL}sso/identity/${qs}`
      return null
    }
    // @ts-ignore
    window.location.href = window.location.href
      ? `${SSO_URL}?redirect_uri=${encodeURIComponent(window.location.href)}`
      : SSO_URL
    return null
  }

  if (fetching) {
    return <PageOverlay />
  }

  if (user) {
    if (!didFirstCall && isSigningIn) {
      setDidFirstCall(true)
      dispatch(resetLastActive(true))
      localStorage.setItem('appLocale', user.attributes?.locale)
    }

    return <>{children}</>
  }

  return <Error statusCode={403} />
}

export default AWSAuth
