import styled from 'styled-components'
import FormField from '@berlitz/form-field'

export const StyledFormField = styled(FormField)`
  label {
    text-align: left;
    font-weight: ${({ theme }) => theme.fontWeight.bold};
    span {
      display: none;
    }
  }
`
