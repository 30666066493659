import React, { useState } from 'react'
import Input from '@berlitz/input'
import { StyledFormField } from './style'
import { injectIntl, WrappedComponentProps, FormattedMessage } from 'react-intl'
import { H1, Flex } from '@berlitz/globals'
import Button from '@berlitz/button'
import TextArea from '@berlitz/text-area'
import styled from 'styled-components'
import Select from '@berlitz/select'
import Spacer from '@berlitz/spacer'
import { IWebToCase } from './interface'

export const Wrapper = styled.div`
  padding: ${({ theme }) => theme.space.sm};
  direction: ltr;
  h1 {
    text-align: center;
  }
`

const WebToCase: React.FC<IWebToCase & WrappedComponentProps & { onClose: () => void }> = ({
  intl,
  onClose,
  extEmail = '',
  extDescription = '',
  extSubject = '',
  extFullname = '',
  extCourseType = '',
  headerTitle = 'Raise a case',
}) => {
  const [subject, setSubject] = useState('')
  const [tempSubject, setTempSubject] = useState(extSubject || '')
  const [courseType, setCourseType] = useState(extCourseType || '')
  const [description, setDescription] = useState(extDescription || '')
  const [tempDescription, setTempDescription] = useState('')
  const [email, setEmail] = useState(extEmail || '')
  const [fullname, setFullname] = useState(extFullname || '')

  const doDisable = (): Boolean => {
    return !(!!email && !!description && !!subject)
  }

  React.useEffect(() => {
    const messageContext = { courseType, extDescription, extFullname, fullname, email }
    __DEV__ && console.log('Web To Case', messageContext)
    window.DD_LOGS && window.DD_LOGS.logger.info('Web To Case', messageContext)
  }, [courseType, extDescription, extFullname, fullname, email])

  return (
    <Wrapper>
      <H1>
        <FormattedMessage id={headerTitle} />
      </H1>
      <form action={`${process.env.WTC_URL}/servlet/servlet.WebToCase?encoding=UTF-8`} method="POST">
        <input type="hidden" name="orgid" value={process.env.WTC_orgid} />
        <input type="hidden" name="retURL" value={`${process.env.SITE_URL}case-submitted`} />
        {__DEV__ && (
          <>
            <input type="hidden" name="debug" value={__DEV__ ? 1 : 0} />
            <input type="hidden" name="debugEmail" value="francis.lamayo@berlitz.com" />
          </>
        )}
        <input type="hidden" name="type" value="Technical Support" />
        <input type="hidden" name={process.env.WTC_sub_type} value="System Access" />
        <input type="hidden" id="recordType" name="recordType" value={process.env.WTC_recordType} />
        <input type="hidden" id="external" name="external" value="1" />
        <input type="hidden" id="origin" name="origin" value="Portal" />
        <input type="hidden" id="subject" name="subject" value={subject} />
        <input type="hidden" id="description" name="description" value={description} />
        <input type="hidden" id="priority" name="priority" value="High" />
        {Boolean(extEmail) ? <input type="hidden" id="email" name="email" value={email} /> : <></>}

        <>
          <StyledFormField label={intl.formatMessage({ id: 'Subject' })} required id="subject" validationMessageLeft>
            <Input
              name="subject"
              required
              type="text"
              placeholder={intl.formatMessage({ id: 'Subject' })}
              value={tempSubject}
              onChange={(e) => {
                if (courseType) {
                  setSubject(`${courseType} | ${e.target.value}`)
                }
                setTempSubject(e.target.value)
              }}
            />
          </StyledFormField>
          <StyledFormField
            label={intl.formatMessage({ id: 'Description' })}
            required
            id="description"
            validationMessageLeft
          >
            <TextArea
              placeholder={intl.formatMessage({ id: 'Describe the issue you are having.' })}
              name="description"
              value={tempDescription}
              onChange={(e) => {
                if (extDescription) {
                  setDescription(`Code: ${extDescription} | ${e.target.value}`)
                }
                setTempDescription(e.target.value)
              }}
            />
          </StyledFormField>
          <StyledFormField label={intl.formatMessage({ id: 'Full name' })} required id="name" validationMessageLeft>
            <Input
              required
              type="text"
              placeholder={intl.formatMessage({ id: 'Full name' })}
              id="name"
              name="name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
              disabled={Boolean(extFullname)}
            />
          </StyledFormField>
          <StyledFormField label={intl.formatMessage({ id: 'Email' })} required id="email" validationMessageLeft>
            <Input
              required
              type="email"
              placeholder={intl.formatMessage({ id: 'Email' })}
              id="email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              disabled={Boolean(extEmail)}
            />
          </StyledFormField>
          <StyledFormField label={intl.formatMessage({ id: 'Course type' })} required id="phone" validationMessageLeft>
            <Select
              ghost
              placeholder="Select course type"
              name="courseType"
              options={[
                {
                  id: 'Online',
                  label: intl.formatMessage({ id: 'Online' }),
                  value: 'Online',
                },
                {
                  id: 'Face to face',
                  label: intl.formatMessage({ id: 'Face to face' }),
                  value: 'Face to face',
                },
              ]}
              required
              onChange={(e) => {
                setCourseType(e.target.value)
                setSubject(`${e.target.value} | ${tempSubject}`)
              }}
              defaultValue={courseType}
            />
          </StyledFormField>
          <Flex justifyContent="center">
            <Spacer right>
              <Button onClick={onClose}>
                <FormattedMessage id="Cancel" />
              </Button>
            </Spacer>
            <Button
              type="submit"
              // @ts-ignore
              disabled={doDisable()}
            >
              <FormattedMessage id="Submit" />
            </Button>
          </Flex>
        </>
      </form>
    </Wrapper>
  )
}

export default injectIntl(WebToCase)
