import { TimezoneType, ZoneType } from '@interfaces/InstructorsSettings'
import timezones from '@utils/dataset/timezones.json'
import flattenDeep from 'lodash/flattenDeep'
import moment from 'moment'

export const getBirthDates = (Birthdate?: string) => {
  const birthdate = moment(Birthdate, 'YYYY-MM-DD')
  let birthDates = {
    BirthDay: '',
    BirthMonth: '',
    BirthYear: '',
  }

  if (birthdate.isValid()) {
    birthDates = {
      BirthDay: birthdate.format('DD'),
      BirthMonth: birthdate.format('MMMM'),
      BirthYear: birthdate.format('YYYY'),
    }
  }

  return birthDates
}

export const accountFields = [
  'FirstName',
  'LastName',
  'NickName',
  'Profession',
  'Company',
  'Street',
  'City',
  'State',
  'PostalCode',
  'Country',
  'Email',
  'MobilePhone',
  'PreferredTimezone',
  'PortalLanguage',
  'NativeLanguage',
  'Birthdate',
  'CustomerId',
  'RecordId',
  'Student',
]

export const getSelectedTimezone = (timezone?: string) => {
  const flattenedTimezones: ZoneType[] = []
  // @ts-ignore
  timezones.forEach((tz: TimezoneType) => flattenedTimezones.push(tz.children))
  return flattenDeep(flattenedTimezones).find((zone) => zone.id === timezone)
}

export const getPortalTimezones = () =>
  [...timezones].map((tz) =>
    tz.title === 'GMT'
      ? {
          ...tz,
          children: tz.children.map((tzch) => ({
            ...tzch,
            id: `${tzch.id}/${tzch.id}`,
          })),
        }
      : tz
  )

export const getGMTValue = (value) => (value === 'GMT/GMT' ? 'GMT' : value)

export const formDaysDropdown = (count: number) =>
  [...Array(count).keys()].map((key) => ({
    id: key + 1,
    label: (key + 1).toString(),
    value: (key + 1).toString(),
  }))

export const getDaysDropdown = (day: string) => {
  const birthdate = moment(day, 'YYYY-MM-DD')
  return formDaysDropdown(birthdate.isValid() ? birthdate.daysInMonth() : 31)
}

export const getMonthsDropdown = () =>
  moment.months().map((month) => ({
    id: month,
    label: month,
    value: month,
  }))

export const changeBirthDays = (month?: string, year?: string) => {
  const birthdate = moment(`${month} ${year}`, 'MMMM YYYY')
  return formDaysDropdown(birthdate.isValid() ? birthdate.daysInMonth() : 31)
}

export const getYearsDropdown = () =>
  [...Array(80).keys()].map((key) => ({
    id: moment().subtract(key, 'year').format('YYYY'),
    label: moment().subtract(key, 'year').format('YYYY'),
    value: moment().subtract(key, 'year').format('YYYY'),
  }))
