import { AutoEnrollment } from './interface'

const Image = '/images/onboarding.svg'

export enum CodeId {
  REQUIRED_FIELD_MISSING = 'REQUIRED_FIELD_MISSING',
  PRODUCT_DEFINITION_DOES_NOT_EXIST = 'PRODUCT_DEFINITION_DOES_NOT_EXIST',
  ACCOUNT_DOES_NOT_EXIST = 'ACCOUNT_DOES_NOT_EXIST',
  ENROLLMENT_NOT_ENABLED = 'ENROLLMENT_NOT_ENABLED',
  CUSTOM_RESTRICTED_ACCESS = 'CUSTOM_RESTRICTED_ACCESS',
  ENROLLMENT_INITIALIZED = 'ENROLLMENT_INITIALIZED',
  ALREADY_ENROLLED_SAME_LANGUAGE = 'ALREADY_ENROLLED_SAME_LANGUAGE',
  ALREADY_ENROLLED_DIFFERENT_LANGUAGE = 'ALREADY_ENROLLED_DIFFERENT_LANGUAGE',
  ENROLLMENT_EXISTS = 'ENROLLMENT_EXISTS',
  CUSTOM_VALIDATION_NOT_FOUND = 'CUSTOM_VALIDATION_NOT_FOUND',
  ENROLLMENT_PROVISION_FAILED = 'ENROLLMENT_PROVISION_FAILED',
  ENROLLMENT_ERROR = 'ENROLLMENT_ERROR',
}

export const customRestrictedAccessMessage = (companyName: string = 'your Web') =>
  `Berlitz on Demand subscription is only available in the ${companyName} Extra package`

export const autoEnrollmentsByCode = new Map<string, AutoEnrollment>()
autoEnrollmentsByCode.set(CodeId.REQUIRED_FIELD_MISSING, {
  Code: CodeId.REQUIRED_FIELD_MISSING,
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})
autoEnrollmentsByCode.set(CodeId.PRODUCT_DEFINITION_DOES_NOT_EXIST, {
  Code: CodeId.PRODUCT_DEFINITION_DOES_NOT_EXIST,
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})
autoEnrollmentsByCode.set(CodeId.ACCOUNT_DOES_NOT_EXIST, {
  Code: CodeId.ACCOUNT_DOES_NOT_EXIST,
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})
autoEnrollmentsByCode.set(CodeId.ENROLLMENT_NOT_ENABLED, {
  Code: CodeId.ENROLLMENT_NOT_ENABLED,
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})
autoEnrollmentsByCode.set(CodeId.CUSTOM_RESTRICTED_ACCESS, {
  Code: CodeId.CUSTOM_RESTRICTED_ACCESS,
  Message: customRestrictedAccessMessage('your Web'),
  Image,
})
autoEnrollmentsByCode.set(CodeId.ENROLLMENT_INITIALIZED, {
  Code: '',
  Message: 'Enrolment in progress',
  Image,
})
autoEnrollmentsByCode.set(CodeId.ALREADY_ENROLLED_SAME_LANGUAGE, {
  Code: CodeId.ALREADY_ENROLLED_SAME_LANGUAGE,
  Message:
    'At the moment, you cannot select a different language. In your MultiLife package, you can change the language of study every 3 months.',
  Image,
})
autoEnrollmentsByCode.set(CodeId.ALREADY_ENROLLED_DIFFERENT_LANGUAGE, {
  Code: CodeId.ALREADY_ENROLLED_DIFFERENT_LANGUAGE,
  Message:
    'At the moment, you cannot select a different language. In your MultiLife package, you can change the language of study every 3 months.',
  Image,
})
autoEnrollmentsByCode.set(CodeId.ENROLLMENT_EXISTS, {
  Code: CodeId.ENROLLMENT_EXISTS,
  Message: 'Enrolment in progress',
  Image,
})
autoEnrollmentsByCode.set(CodeId.CUSTOM_VALIDATION_NOT_FOUND, {
  Code: CodeId.CUSTOM_VALIDATION_NOT_FOUND,
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})
autoEnrollmentsByCode.set(CodeId.ENROLLMENT_ERROR, {
  Code: '',
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})

autoEnrollmentsByCode.set(CodeId.ENROLLMENT_PROVISION_FAILED, {
  Code: '',
  Message: 'Your enrollment was not created, please raise a support case',
  Image,
})
