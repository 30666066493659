import React from 'react'
import { useSelector } from 'react-redux'
import { StoreProps } from '@interfaces/StoreState'
import * as queryString from 'query-string'
import PageOverlay from '@components/PageOverlay'
import { POST_AUTO_ENROLLMENT } from '@mutations/autoEnrollment'
import { useMutation } from 'react-apollo'
import { AutoEnrollment } from './interface'
import EAPModal from './EAPModal'
import { FormattedMessage, IntlProvider } from 'react-intl'
import Button from '@berlitz/button'
import { autoEnrollmentsByCode, CodeId, customRestrictedAccessMessage } from './AutoEnrollmentCode'
import useBeforeunload from '@hooks/useBeforeunload'
import { useIntl } from 'react-intl'
import useCustomLazyQuery from '@hooks/useCustomLazyQuery'
import { GET_TRANSLATIONS } from '@queries/translations'
import { getLocale } from '@layouts/PortalTranslations/utils'

const EnrollmentAutoProvistion: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const intl = useIntl()
  const [openModal, setOpenModal] = React.useState(false)
  const { product_definition_id } = queryString.parse(window.location.search)
  const { SSOFederatedIdentityId, StudentFirstName, StudentLastName, StudentEmail, StudentExternalId } = useSelector(
    ({ session }: StoreProps) => {
      const SSOFederatedIdentityId =
        session?.user?.signInUserSession?.idToken?.payload?.['custom:federated_override'] ??
        session?.user?.signInUserSession?.idToken?.payload?.identities?.[0]?.providerName
      const StudentFirstName = session?.user?.signInUserSession?.idToken?.payload?.given_name
      const StudentLastName = session?.user?.signInUserSession?.idToken?.payload?.family_name
      const StudentEmail = session?.user?.signInUserSession?.idToken?.payload?.email
      const StudentExternalId =
        session?.user?.signInUserSession?.idToken?.payload?.['custom:sub_override'] ??
        session?.user?.signInUserSession?.idToken?.payload?.['custom:externalUserId']

      return {
        SSOFederatedIdentityId,
        StudentFirstName,
        StudentLastName,
        StudentEmail,
        StudentExternalId,
      }
    }
  )

  let ProductDefinitionId = product_definition_id
  if (!product_definition_id) {
    if (StudentEmail) {
      ProductDefinitionId = localStorage.getItem(`EAP-${StudentEmail}`)
    }
  }

  const [initialLoading, setInitialLoading] = React.useState(Boolean(ProductDefinitionId))

  useBeforeunload(() => {
    if (window) {
      StudentEmail && localStorage.removeItem(`EAP-${StudentEmail}`)
    }
  })

  const [getTranslations, { loading: transLoading, data: translations }] = useCustomLazyQuery(GET_TRANSLATIONS)

  const [postAutoEnrollment, { loading, data, error }] = useMutation<{ postAutoEnrollment: AutoEnrollment }>(
    POST_AUTO_ENROLLMENT,
    {
      onCompleted: () => {
        setOpenModal(true)
      },
      onError: () => {
        setOpenModal(true)
      },
    }
  )

  const code = data?.postAutoEnrollment?.Code ?? CodeId.ENROLLMENT_ERROR

  if (code === CodeId.ENROLLMENT_ERROR) {
    const autoEnrollment = autoEnrollmentsByCode.get(CodeId.ENROLLMENT_ERROR)!
    autoEnrollmentsByCode.set(CodeId.ENROLLMENT_ERROR, {
      ...autoEnrollment,
      Code: error?.message,
      Message: autoEnrollment.Message,
    })
  }

  if (code === CodeId.ENROLLMENT_PROVISION_FAILED) {
    const autoEnrollment = autoEnrollmentsByCode.get(CodeId.ENROLLMENT_PROVISION_FAILED)!
    autoEnrollmentsByCode.set(CodeId.ENROLLMENT_PROVISION_FAILED, {
      ...autoEnrollment,
      Code: `${data?.postAutoEnrollment?.Code}: ${data?.postAutoEnrollment?.Message}`,
      Message: autoEnrollment.Message,
    })
  }

  if (code === CodeId.CUSTOM_RESTRICTED_ACCESS) {
    //const company = last<string | undefined>(SSOFederatedIdentityId.split('-'))
    const company = 'MultiLife'
    const autoEnrollment = autoEnrollmentsByCode.get(CodeId.CUSTOM_RESTRICTED_ACCESS)!
    autoEnrollmentsByCode.set(CodeId.CUSTOM_RESTRICTED_ACCESS, {
      ...autoEnrollment,
      Code: error?.message,
      Message: customRestrictedAccessMessage(company),
    })
  }

  const onClose = () => setOpenModal(false)

  React.useEffect(() => {
    if (
      ProductDefinitionId &&
      SSOFederatedIdentityId &&
      StudentFirstName &&
      StudentLastName &&
      StudentEmail &&
      StudentExternalId
    ) {
      // Call the API to provision the enrollment
      postAutoEnrollment({
        variables: {
          ProductDefinitionId,
          SSOFederatedIdentityId,
          StudentFirstName,
          StudentLastName,
          StudentEmail,
          StudentExternalId,
        },
      })

      setInitialLoading(false)
    }
  }, [product_definition_id])

  const defaultLang = data?.postAutoEnrollment?.EnrollmentSupportLanguageDefault || intl?.locale || 'en'
  React.useEffect(() => {
    if (defaultLang && ProductDefinitionId) {
      const locale = defaultLang
      getTranslations({ locale: getLocale(locale) })
    }
  }, [defaultLang, ProductDefinitionId])

  const messages = React.useMemo<Record<string, string> | undefined>(() => {
    try {
      const messages = JSON.parse(translations?.localization?.translations || null)
      return messages
    } catch (e) {}
  }, [translations])

  React.useEffect(() => {
    if ([CodeId.ALREADY_ENROLLED_SAME_LANGUAGE, CodeId.ENROLLMENT_EXISTS].includes(code as CodeId)) {
      StudentEmail && localStorage.removeItem(`EAP-${StudentEmail}`)
      location.href = '/'
    }
  }, [code])

  /**
   * Make sure to show the loading overlay when the enrollment is in progress
   */
  if (
    initialLoading ||
    loading ||
    transLoading ||
    [CodeId.ALREADY_ENROLLED_SAME_LANGUAGE, CodeId.ENROLLMENT_EXISTS].includes(code as CodeId)
  ) {
    return <PageOverlay />
  }

  return (
    <>
      {children}
      <IntlProvider
        locale={getLocale(defaultLang)}
        key={getLocale(defaultLang)}
        messages={messages}
        onError={(err) => {
          // @ts-ignore
          if (err.code === 'MISSING_TRANSLATION') {
            // @ts-ignore
            __DEV__ && console.warn('Missing translation', err.message)
            return
          }
          throw err
        }}
      >
        <EAPModal
          code={code}
          open={openModal}
          onClose={onClose}
          customButtons={
            <Button>
              <FormattedMessage id="Raise a case" />
            </Button>
          }
        />
      </IntlProvider>
    </>
  )
}

export default EnrollmentAutoProvistion
