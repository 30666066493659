import gql from 'graphql-tag'

export const GET_USER_PROFILE = gql`
  query GetUserProfile($Id: String!, $Type: String!, $Key: String) {
    getUserProfile(Id: $Id, Type: $Type, Key: $Key) {
      Type
      SFId
      Timezone
      InstructorProfile {
        instructorInfo {
          Status
          PreferredTimezone
          PreferredLocale
          PreferredLanguage
          LessonCount
          PortalLanguage
          isDirty
          Id
          EndDateWithBerlitz
          Honorific
          FeedbackScore
          FeedbackCompositeScore
          EnrollmentRatio
          EmailPreferences
          DesiredOvertimeHoursPerWeek
          DesiredOvertimeHoursPerMonth
          DesiredOvertimeHoursPerDay
          DesiredHoursPerWeek
          DesiredHoursPerMonth
          DesiredHoursPerDay
          CutOffTimeZone
          CutOffTime
          ContractType
          CustomerId
          CommunicationPreference
          ColourTheme
          Description
          Bio
          contactInfo {
            Salutation
            LastName
            Id
            FirstName
            Phone
            MailingStreet
            MailingState
            MailingPostalCode
            MailingCountry
            MailingCity
            CurrencyIsoCode
            Email
          }
          Avatar
          AvailabilityType
          AvailabilityOption
          learningcenters {
            Id
            TimeZone
            Name
            IsPrimary
          }
          PortalTimeformat
        }
      }
      StudentProfile {
        StudentProfileInfo {
          VideoAuthority
          Student
          RecordId
          Ranking
          PreferredTimezone
          PreferredLocale
          InductionStatus
          Phone
          OverallPerformanceScore
          MobilePhone
          Milestone
          Level
          Hobbies
          FamilyAndRelationship
          EnableRanking
          EnableCustomerSatisfaction
          Email
          Education
          DisplayName
          FirstLessonDate
          Country
          AboutYou
          City
          Latitude
          Longitude
          PostalCode
          PortalFeaturesEnabled
          State
          Status
          Street
          Purpose
          DetailsOfGoal
          LearningGoal
          LastLessonDate
          EnablePermissions
          Profession
          NickName
          Company
          LastName
          ContactMethod
          FirstName
          Location
          EnableUploadFiles
          EnableGDPR
          CustomerId
          Birthdate
          OtherDetails
          PreferredLanguage
          PortalLanguage
          NativeLanguage
          PortalTimeformat
          Groups
          firstLogin
          lastLogin
          ProgramStudentWrappers {
            ProgramStudentInfo {
              ProgramStudentId
              ProgramId
              LessonsTaken
              TotalLessons
              LessonsRemaining
              LessonsPerSession
              TotalSessionsComplete
              LessonsBooked
              LessonFrequency
              DeliveryMode
              ProgramType
              Language
              ProgramName
              ProgramEnrollDate
              ParentProgramId
              LearningCenterName
              LearningCenterId
              UnitsPerLesson
              RescheduleCount
              TotalRescheduleAllowed
              PremiumPlusRate
              PortalNotNecessary
              IsPrivate
              IsLead
              IsGroup
              IsChild
              BlockScheduling
              ContractEndDate
              ContractStartDate
              Status
              ValidforOrientation
              ValidforCounselling
              VirtualClassRoomProvider
              OnlineLessonURL
              CounsellingStatus
              OrientationDate
              Materials {
                RecordId
                NewRowPlaylistId
                LPID
              }
              studentGroupInfo {
                Id
                Name
                MaximumOfStudents
              }
              SingleSessionScheduling
              SessionStartTimes
              BulkScheduling
              HoursBeforeCutOff
              DeliveryLCTimeZone
              CutOffTimeOverride
              CancelCount
              CutOffTime
              CancelCutOffMinutes
              SchedulingMinutesBeforeCutoff
              TotalCancellationAllowed
              MinimumNumberOfLessonsToBeBooked
              GroupConversationClasses
              InstructorUnavailabilityAction
            }
          }
          RegistrationWrappers {
            RegistrationInfo {
              AtRisk
              DisplayName
              TotalLessons
              Status
              RegistrationId
              Region
              FlexLastLevel
              ProgramType
              PlacementTestRequired
              ElectiveRequired
              ProgramEndDate
              TestStatus
              MaxNumberTestAttempts
              Programs {
                VirtualClassRoomProvider
                OnlineLessonURL
                ValidforOrientation
                ValidforCounselling
                UnitsPerLesson
                TotalSessionsComplete
                TotalRescheduleAllowed
                TotalLessons
                TotalCancellationAllowed
                Status
                SingleSessionScheduling
                SessionStartTimes
                RescheduleCount
                ProgramType
                ProgramName
                ProgramId
                ProgramEnrollDate
                PremiumPlusRate
                PortalNotNecessary
                OrientationDate
                FlexVersion
                Materials {
                  RecordId
                  NewRowPlaylistId
                  LPID
                }
                LessonsTaken
                LessonsRemaining
                LessonsPerSession
                LessonsBooked
                LessonFrequency
                LearningCenterName
                LearningCenterId
                LanguageLevel
                Language
                IsGroup
                IsChild
                IsLead
                IsPrivate
                HoursBeforeCutOff
                DeliveryLCTimeZone
                DeliveryLCCountry
                CustomerType
                ContractEndDate
                CancelCount
                BulkScheduling
                BlockScheduling
                CutOffTime
                CutOffTimeOverride
                SchedulingMinutesBeforeCutoff
                CancelCutOffMinutes
                MinimumNumberOfLessonsToBeBooked
                GroupConversationClasses
                InstructorUnavailabilityAction
              }
              Name
              Materials {
                Status
                RegistrationId
                RecordId
                Name
                MaterialType
                MaterialSubType
                MaterialName
                MaterialId
                LPID
                LPExternalName
                IsElective
                IsTest
                HasTest
                IndustrySpecificContent
              }
              LessonsRemaining
              LessonsTaken
              LearningCenterName
              LearningCenterId
              LanguageLevel
              Language
              FlexVersion
              FlexLevelStatus
              TotalLessonsAchievement
              TotalAchievedAttendancePercent
              UnlimitedLiveCoachingDate
              TotalContentUnitsChaptersAchieved
            }
          }
          MyBerlitzEnvironment
          StudentLanguageProfileInfos {
            AllNotesWrittenOnTheTakeaway
            CustomizedToLifeSituations
            CustomizedToWorkSituation
            DoesnotWantStrictCorrection
            FocusOnPronunciation
            GetHintsOrSupport
            HaveRolePlays
            KeepSelfIntroductionsToMinimum
            Language
            LearnPoliteFormalExpressions
            LearnWordsAndExpressions
            NoNaturalWayToSaySomething
            RecordId
            RepeatCorrectedWordsOrPhrases
            SpeakAsMuchAsPossible
            SpeakAtNaturalSpeed
            StrictCorrection
            StudentProfileId
            TaughtFullSentences
          }
        }
      }
    }
  }
`

export const GET_SIMPLE_STUDENT_PROFILE = gql`
  query GetStudentProfile($id: String!) {
    getUserProfile(Id: $id, Type: "Student") {
      StudentProfile {
        StudentProfileInfo {
          VideoAuthority
          Student
          RecordId
          Ranking
          PreferredTimezone
          PreferredLocale
          InductionStatus
          Phone
          OverallPerformanceScore
          MobilePhone
          Milestone
          Level
          Hobbies
          FamilyAndRelationship
          EnableRanking
          EnableCustomerSatisfaction
          Email
          Education
          DisplayName
          FirstLessonDate
          Country
          AboutYou
          City
          Latitude
          Longitude
          PostalCode
          PortalFeaturesEnabled
          State
          Status
          Street
          Purpose
          DetailsOfGoal
          LearningGoal
          LastLessonDate
          EnablePermissions
          Profession
          NickName
          Company
          LastName
          ContactMethod
          FirstName
          Location
          EnableUploadFiles
          EnableGDPR
          CustomerId
          Birthdate
          OtherDetails
          PreferredLanguage
          PortalLanguage
          NativeLanguage
          PortalTimeformat
          Groups
          firstLogin
          lastLogin
          ProgramStudentWrappers {
            ProgramStudentInfo {
              ProgramStudentId
              ProgramId
              LessonsTaken
              TotalLessons
              LessonsRemaining
              LessonsPerSession
              TotalSessionsComplete
              LessonsBooked
              LessonFrequency
              DeliveryMode
              ProgramType
              Language
              ProgramName
              ProgramEnrollDate
              ParentProgramId
              LearningCenterName
              LearningCenterId
              UnitsPerLesson
              RescheduleCount
              TotalRescheduleAllowed
              PremiumPlusRate
              PortalNotNecessary
              IsPrivate
              IsLead
              IsGroup
              IsChild
              BlockScheduling
              ContractEndDate
              ContractStartDate
              Status
              ValidforOrientation
              ValidforCounselling
              VirtualClassRoomProvider
              OnlineLessonURL
              CounsellingStatus
              OrientationDate
              Materials {
                RecordId
                NewRowPlaylistId
                LPID
              }
              studentGroupInfo {
                Id
                Name
                MaximumOfStudents
              }
              SingleSessionScheduling
              SessionStartTimes
              BulkScheduling
              HoursBeforeCutOff
              DeliveryLCTimeZone
              CutOffTimeOverride
              CancelCount
              CutOffTime
              CancelCutOffMinutes
              SchedulingMinutesBeforeCutoff
              TotalCancellationAllowed
              MinimumNumberOfLessonsToBeBooked
            }
          }
          RegistrationWrappers {
            RegistrationInfo {
              AtRisk
              DisplayName
              TotalLessons
              Status
              RegistrationId
              Region
              FlexLastLevel
              ProgramType
              ElectiveRequired
              ProgramEndDate
              TestStatus
              MaxNumberTestAttempts
              Programs {
                VirtualClassRoomProvider
                OnlineLessonURL
                ValidforOrientation
                ValidforCounselling
                UnitsPerLesson
                TotalSessionsComplete
                TotalRescheduleAllowed
                TotalLessons
                TotalCancellationAllowed
                Status
                SingleSessionScheduling
                SessionStartTimes
                RescheduleCount
                ProgramType
                ProgramName
                ProgramId
                ProgramEnrollDate
                PremiumPlusRate
                PortalNotNecessary
                OrientationDate
                FlexVersion
                Materials {
                  RecordId
                  NewRowPlaylistId
                  LPID
                }
                LessonsTaken
                LessonsRemaining
                LessonsPerSession
                LessonsBooked
                LessonFrequency
                LearningCenterName
                LearningCenterId
                LanguageLevel
                Language
                IsGroup
                IsChild
                IsLead
                IsPrivate
                HoursBeforeCutOff
                DeliveryLCTimeZone
                DeliveryLCCountry
                CustomerType
                ContractEndDate
                CancelCount
                BulkScheduling
                BlockScheduling
                CutOffTime
                CutOffTimeOverride
                SchedulingMinutesBeforeCutoff
                CancelCutOffMinutes
                MinimumNumberOfLessonsToBeBooked
              }
              Name
              Materials {
                Status
                RegistrationId
                RecordId
                Name
                MaterialType
                MaterialSubType
                MaterialName
                MaterialId
                LPID
                LPExternalName
                IsElective
                IsTest
                HasTest
              }
              LessonsRemaining
              LessonsTaken
              LearningCenterName
              LearningCenterId
              LanguageLevel
              Language
              FlexVersion
              FlexLevelStatus
              TotalLessonsAchievement
              TotalAchievedAttendancePercent
              UnlimitedLiveCoachingDate
            }
          }
          MyBerlitzEnvironment
          StudentLanguageProfileInfos {
            AllNotesWrittenOnTheTakeaway
            CustomizedToLifeSituations
            CustomizedToWorkSituation
            DoesnotWantStrictCorrection
            FocusOnPronunciation
            GetHintsOrSupport
            HaveRolePlays
            KeepSelfIntroductionsToMinimum
            Language
            LearnPoliteFormalExpressions
            LearnWordsAndExpressions
            NoNaturalWayToSaySomething
            RecordId
            RepeatCorrectedWordsOrPhrases
            SpeakAsMuchAsPossible
            SpeakAtNaturalSpeed
            StrictCorrection
            StudentProfileId
            TaughtFullSentences
          }
        }
      }
    }
  }
`
// get id by encrypted text
export const GET_USER_BY_ENCR = gql`
  query GetCognitoUserBySId($encryptedStudentId: String!) {
    getCognitoUserBySId(encryptedStudentId: $encryptedStudentId) {
      email
      studentProfileId
      portalLanguage
      userName
    }
  }
`
