import gql from 'graphql-tag'

export const POST_AUTO_ENROLLMENT = gql`
  mutation PostAutoEnrollment(
    $ProductDefinitionId: String!
    $SSOFederatedIdentityId: String!
    $StudentFirstName: String!
    $StudentLastName: String!
    $StudentEmail: String!
    $StudentExternalId: String!
  ) {
    postAutoEnrollment(
      AutoEnrollmentInput: {
        ProductDefinitionId: $ProductDefinitionId
        SSOFederatedIdentityId: $SSOFederatedIdentityId
        StudentFirstName: $StudentFirstName
        StudentLastName: $StudentLastName
        StudentEmail: $StudentEmail
        StudentExternalId: $StudentExternalId
      }
    ) {
      Code
      Message
      EnrollmentSupportLanguageDefault
    }
  }
`
