import useCustomLazyQuery from '@hooks/useCustomLazyQuery'
import { StoreProps } from '@interfaces/StoreState'
import { getLocale } from '@layouts/PortalTranslations/utils'
import { GET_TRANSLATIONS } from '@queries/translations'
import { updateLocale } from '@redux/actions/intl'
import { PROFILE_TYPE } from '@utils/constants'
import isEqual from 'lodash/isEqual'
import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

const UserTranslations: React.FC<{ defaultLang?: string; children: React.ReactNode }> = ({ children, defaultLang }) => {
  const dispatch = useDispatch()
  const { lang, storedMessages } = useSelector(({ userProfile, intl }: StoreProps) => ({
    lang:
      userProfile?.info?.Type === PROFILE_TYPE.STUDENT
        ? userProfile?.info?.StudentProfile?.StudentProfileInfo?.PortalLanguage
        : userProfile?.info?.InstructorProfile?.instructorInfo?.PortalLanguage,
    storedMessages: intl.messages,
  }))

  const [getTranslations, { data: translations }] = useCustomLazyQuery(GET_TRANSLATIONS)
  const initialNow = Date.now()

  useEffect(() => {
    try {
      const messages = JSON.parse(translations?.localization?.translations || null)
      if (messages !== '' && !isEqual(messages, storedMessages)) {
        dispatch(updateLocale({ locale: getLocale(lang || defaultLang), messages }))
      }
    } catch (e) {
      console.error('Translation Error', e)
    }
  }, [translations])

  useEffect(() => {
    const locale = lang || defaultLang
    const loadedLang = localStorage.getItem('loadedLocale')

    if (!locale || loadedLang === locale) return
    localStorage.setItem('loadedLocale', locale)
    localStorage.setItem('appLocale', locale)
    getTranslations({ locale: getLocale(locale) })
  }, [lang])

  return (
    <>
      <IntlProvider
        locale={getLocale(lang)}
        key={getLocale(lang)}
        messages={storedMessages}
        initialNow={initialNow}
        onError={(err) => {
          // @ts-ignore
          if (err.code === 'MISSING_TRANSLATION') {
            // @ts-ignore
            __DEV__ && console.warn('Missing translation', err.message)
            return
          }
          throw err
        }}
      >
        {children}
      </IntlProvider>
    </>
  )
}

export default UserTranslations
